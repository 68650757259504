var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "modal-notify",
      attrs: {
        width: "35%",
        "show-close": false,
        visible: _vm.show,
        "append-to-body": "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("div", { staticClass: "text-8xl" }, [
            _vm.iconType === "warning"
              ? _c("i", { staticClass: "el-icon-warning-outline" })
              : _vm._e(),
            _vm._v(" "),
            _vm.iconType === "question"
              ? _c("i", { staticClass: "el-icon-question" })
              : _vm._e(),
            _vm._v(" "),
            _vm.iconType === "success"
              ? _c("i", { staticClass: "el-icon-circle-check" })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm._t("modalBody"),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer d-flex justify-content-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_vm._t("modalFooter")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }