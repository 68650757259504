<template>
  <modal-notification
    icon-type="warning"
    :show-modal="showModal"
    @handleCloseDialog="handleCloseDialog"
  >
    <template slot="modalBody">
      <h2 class="text-bold">削除します。よろしいですか？</h2>
      <h6>{{ detailContent }}</h6>
    </template>
    <template slot="modalFooter">
      <el-button type="info" @click="confirm(false)">キャンセル</el-button>
      <el-button type="primary" @click="confirm(true)">
        削除
      </el-button>
    </template>
  </modal-notification>
</template>

<script>
import ModalNotification from '@/components/Modal/ModalNotification';
export default {
  name: 'ModalConfirmDelete',
  components: {
    ModalNotification,
  },
  props: {
    detailContent: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },

  methods: {
    confirm(status) {
      this.$emit('confirm', status);
    },
    handleCloseDialog(){
      this.$emit('handleCloseDialog');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
