var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-notification",
    {
      attrs: { "icon-type": "success", "show-modal": _vm.showModal },
      on: { handleCloseDialog: _vm.handleCloseDialog },
    },
    [
      _c("template", { slot: "modalBody" }, [
        _vm.type === _vm.SUCCESS_TYPE.SAVE
          ? _c("h2", { staticClass: "text-bold" }, [_vm._v("登録されました")])
          : _vm._e(),
        _vm._v(" "),
        _vm.type === _vm.SUCCESS_TYPE.DELETE
          ? _c("h2", { staticClass: "text-bold" }, [_vm._v("削除しました")])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.successHtml) } }),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modalFooter" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.closeModal } },
            [_vm._v("\n      OK\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }