export const PAGINATION_CONSTANT = {
  DEFAULT_PER_PAGE: 10,
};

export const ACTION = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  COPY: 'COPY',
  DETAIL: 'DETAIL',
};

export const SUCCESS_TYPE = {
  DELETE: 'DELETE',
  SAVE: 'SAVE',
};
export const SAVE_TYPE_CAMPAIGN = {
  DRAFT: 'draft',
  QUEUED: 'Queued',
};

export const PAGINATION_SELECTED_RECORDS = [10, 20, 30, 50, 100];

export const FILE_TYPE = {
  EXEL: 'text/plain',
  CSV: 'text/csv',
  TXT: 'text/plain',
  PDF: 'application/pdf',
  MP4: 'video/mp4',
  MP3: 'audio/mpeg',
};

export const LIMIT_FILE_SIZE = {
  NORMAL_UPLOAD_FILE: 30 * 1024 * 1024, // 30MB
  SKILLS_UPLOAD_FILE: 100 * 1024 * 1024, // 3MB
};

export const SENDING_RATE_OPTIONS = [
  {
    text: '高',
    value: 1,
    times: 200,
  },
  {
    text: '中',
    value: 2,
    times: 100,
    isDefault: true,
  },
  {
    text: '低',
    value: 3,
    times: 50,
  },
];

export const WEEK_DAY_OPTIONS = [
  {
    text: '日',
    key: '0',
  },
  {
    text: '月',
    key: '1',
  },
  {
    text: '火',
    key: '2',
  },
  {
    text: '水',
    key: '3',
  },
  {
    text: '木',
    key: '4',
  },
  {
    text: '金',
    key: '5',
  },
  {
    text: '土',
    key: '6',
  },
];

export const CAMPAIGN_STATUS = {
  PREPARING: {
    key: 1,
    text: '準備中',
    backgroundClass: 'preparing',
  },
  SCHEDULE: {
    key: 2,
    text: '予約済',
    backgroundClass: 'reservation',
  },
  IN_PROGRESS: {
    key: 3,
    text: '実施中',
    backgroundClass: 'in-progress',

  },
  COMPLETE: {
    key: 4,
    text: '終了',
    backgroundClass: 'finish',
  },
};

export const API_NO_AUTH = [
  '/page/getMenu',
];
export const ERROR_SERVE = {
  ERROR: 500,
};
