var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "viewport" },
    [
      _c("Header", { on: { handleLogout: _vm.handleLogout } }),
      _vm._v(" "),
      _c("AppMain"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "display-app container" },
        [_c("Loading", { attrs: { "props-is-loading": _vm.propsIsLoading } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }