<template>
  <div class="viewport">
    <Header @handleLogout="handleLogout" />
    <AppMain />
    <div class="display-app container">
      <Loading :props-is-loading="propsIsLoading" />
    </div>
  </div>
</template>
<script>
import Header from './components/Header.vue';
import AppMain from './components/AppMain.vue';
import Loading from '@/components/Loading';

import { MakeToast } from '@/utils/toastMessage';
import Cookies from 'js-cookie';

export default {
  name: 'Layout',
  components: {
    Header,
    AppMain,
    Loading,
  },

  computed: {
    keyChange() {
      return this.$route.fullPath;
    },
    propsIsLoading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    keyChange() {
      if (this.$route.fullPath === '') {
        this.$router.push({ path: '' });
      }

      this.$store.dispatch('permission/setCurrentRoutes', this.$route.fullPath);
    },
  },

  async created() {
    // set language = ja
    this.$store
      .dispatch('app/setLanguage', Cookies.get('language') || 'ja')
      .then(() => {
        // this.$i18n.locale = constLang.JAPANESE;
        this.$i18n.locale = Cookies.get('language') || 'ja';
        // MakeToast({
        //   variant: 'success',
        //   title: 'Success',
        //   content: 'Change language successfully',
        // });
      })
      .catch(() => {
        MakeToast({
          variant: 'danger',
          title: 'Danger',
          content: 'Language change failed',
        });
      });

    this.$bus.on('responseStatusCode', (statusCode) => {
      if (statusCode === 401) {
        this.$store.commit('auth/logout');
      }
    });
    this.$bus.on('responseErrorCode', (errorCode) => {
      if (errorCode === 403) {
        this.$toast.error(this.$t('error_message.not_permission'));
        this.$store.commit('auth/logout');
      }
    });
  },

  methods: {
    handleLogout() {
      // this.$store.dispatch('user/logout').then(() => {
      //   this.$router.push('/login');
      // });
      this.$store
        .dispatch('user/doLogout')
        .then(() => {
          this.$store
            .dispatch('permission/resetRoutes')
            .then(() => {
              this.$store
                .dispatch('permission/setCurrentRoutes', '')
                .then(() => {
                  this.$router.push('/login');
                })
                .catch(() => {
                  MakeToast({
                    variant: 'danger',
                    title: this.$t('DANGER'),
                    content: this.$t('TOAST_HAVE_ERROR'),
                  });
                });
            })
            .catch(() => {
              MakeToast({
                variant: 'danger',
                title: this.$t('DANGER'),
                content: this.$t('TOAST_HAVE_ERROR'),
              });
            });

          // Reset values = null
        })
        .catch(() => {
          MakeToast({
            variant: 'danger',
            title: this.$t('DANGER'),
            content: this.$t('TOAST_HAVE_ERROR'),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.viewport {
  height: 100vh;
}

.display-app {
  // margin-top: 25px;
  margin-top: 15px;
  flex-grow: 1; /* Kéo dãn main để chiếm hết không gian còn lại */
}

@media screen and (max-width:480px) {
  .display-app {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0;
  }
}
</style>
