<template>
  <div class="d-flex align-items-center">
    <el-select v-model="perPage" class="select-perpage" size="small" placeholder="" @change="changeSize">
      <el-option v-for="(item, idx) in listPerPage" :key="idx" :label="item" :value="item" />
    </el-select>
    <span class="ml-2">件表示</span>
  </div>
</template>

<script>
export default {
  name: 'Example',
  components: {
  },
  props: {
    size: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      listPerPage: [10, 25, 50, 100],
      perPage: 10,
    };
  },
  watch: {
    size(value) {
      this.perPage = value;
    },
  },
  created() {
  },
  methods: {
    changeSize() {
      this.$emit('changeSize', this.perPage);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
