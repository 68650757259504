<template>
  <div class="select-container">
    <el-form-item
      :prop="keyValidate"
      :rules="rules"
      :label="label"
    >
      <el-select
        :value="value"
        :size="size"
        :placeholder="placeholder"
        :disabled="disabled"
        @change="handleChange"
      >
        <el-option
          v-for="(item, idx) in options"
          :key="idx"
          :label="item.text"
          :value="item.value"
        />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>

export default {
  name: 'NormalSelect',
  components: {
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    keyValidate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },

    },
    options: {
      type: Array,
      default: () => {
        return [];
      },

    },
  },
  data() {
    return {
      inputModel: '',
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  created() {
  },
  methods: {
    handleInput(e) {
      // Emit the input event for v-model to update the parent data
      this.$emit('input', e);
    },
    handleChange(value) {
      this.$emit('input', value);
      this.$emit('update:value', value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
::v-deep .el-form-item__content {
  line-height: 22px;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
