const SUPPER_ADMIN = 'supper_admin';
const ADMIN = 'admin';
const VIEWER = 'viewer';

const ROLE = {
  // Key role
  SUPPER_ADMIN,
  ADMIN,
  VIEWER,
  TYPE_SUPER_ADMIN: 1,
  TYPE_USER: 2,

  // List role
  LIST_ROLE: [
    SUPPER_ADMIN,
    ADMIN,
    VIEWER,
  ],
};

export default ROLE;
