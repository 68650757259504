var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-item d-flex align-items-center" },
    [
      _c("span", { staticClass: "mr-2 text-nowrap" }, [_vm._v("検索")]),
      _vm._v(" "),
      _c("el-input", {
        attrs: {
          value: _vm.value,
          size: "small",
          placeholder: _vm.placeholder,
          "suffix-icon": "el-icon-search",
          clearable: "",
        },
        on: {
          input: _vm.handleInput,
          change: _vm.handleChange,
          clear: function ($event) {
            return _vm.$emit("clear")
          },
        },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("pressEnter")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }