var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-container" },
    [
      _c(
        "el-form-item",
        {
          attrs: { prop: _vm.keyValidate, rules: _vm.rules, label: _vm.label },
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                value: _vm.value,
                size: _vm.size,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
              },
              on: { change: _vm.handleChange },
            },
            _vm._l(_vm.options, function (item, idx) {
              return _c("el-option", {
                key: idx,
                attrs: { label: item.text, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }