import Layout from '@/layout';

const SenderSetting = {
  path: '/sender-settings',
  name: '',
  meta: {
    title: '送信元設定',
    breadcrumb: '',
  },
  component: Layout,
  redirect: { name: 'SenderSetting' },
  children: [
    {
      path: '',
      name: 'SenderSettingList',
      meta: {
        title: '送信元設定',
        breadcrumb: '送信元設定',
      },
      component: () => import('@/pages/SenderSetting/index.vue'),
    },
  ],
};

export default SenderSetting;

