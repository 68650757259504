import Vue from 'vue';
import store from '@/store';
import i18n from '@/lang';
/**
 * Show MakeToast
 */
export const MakeToast = ({ variant = null, title, content, toaster = 'b-toaster-top-center', autoHideDelay = 2500 }) => {
  const vm = new Vue();
  const currentToastList = store.getters.listMessToast;

  const listMessDuplicate = ['トークンの有効期限が切れています', 'Token expire.',
    'トークンが提供されていません', 'Token not provided.',
    'Session timed out. Please log in again', 'セッションがタイムアウトしました。再度ログインしてください'];
  const filterListToast = currentToastList.filter(x => (new Date().getTime() - x.time) < 1500);
  const existMsg = filterListToast.find(x => listMessDuplicate.includes(x.msg));

  let newListToast = [...filterListToast];
  if (!existMsg) {
    newListToast = [
      ...newListToast,
      {
        msg: content,
        time: new Date().getTime(),
      },
    ];
    vm.$bvToast.toast(content, {
      title: title,
      variant: variant,
      toaster: toaster,
      solid: true,
      autoHideDelay: autoHideDelay,
      appendToast: true,
    });
    setTimeout(() => {
      const toasts = document.getElementsByClassName('b-toaster');
      for (const toast of toasts) {
        toast.style.zIndex = 5000;
      }
    }, 0);
  }

  store.dispatch('app/saveListToast', newListToast);
};

export const toastSuccess = (mess = '') => {
  MakeToast({
    variant: 'success',
    title: i18n.t('SUCCESS'),
    content: mess || '成功しました',
  });
};

export const toastError = (mess = '') => {
  MakeToast({
    variant: 'danger',
    title: i18n.t('DANGER'),
    content: mess || 'Have error occurr',
  });
};

export const toastServerErr = (mess = '') => {
  MakeToast({
    variant: 'danger',
    title: i18n.t('DANGER'),
    content: mess || 'Have something error',
  });
};
