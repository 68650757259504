// function getRevertRouter() {
//   const REVERT = localStorage.getItem('revertRouter');

//   if (REVERT) {
//     return JSON.parse(REVERT);
//   }
//   return null;
// }

function getCurrentURL() {
  const CURRENT_URL = localStorage.getItem('currentURL');

  if (CURRENT_URL) {
    return JSON.parse(CURRENT_URL);
  }
  return null;
}

const state = {
  routerInfo: {},
  revertRouter: null,
  currentURL: getCurrentURL(),
};

const mutations = {
  SET_ALL_ROUTER_KEY: (state, data) => {
    if (data.tabKey) {
      state.routerInfo = {
        ... state.routerInfo,
        [data.routerName]: {
          ...state.routerInfo[data.routerName],
          [data.tabKey]: {
            params: data?.params,
            queries: data?.queries,
            path: data?.path,
            currentTab: data?.currentTab,
          },
        },
      };
    } else {
      state.routerInfo = {
        ... state.routerInfo,
        [data.routerName]: {
          params: data?.params,
          queries: data?.queries,
          path: data?.path,
        },
      };
    }
  },
  SET_REVERT_ROUTER: (state, revert_router) => {
    state.revertRouter = revert_router;
  },
  SET_CURRENT_URL: (state, current_url) => {
    state.currentURL = current_url;
  },

};

const actions = {
  setRouterWithParamQuery({ commit }, data) {
    commit('SET_ALL_ROUTER_KEY', data);
  },
  setRevertRouter({ commit }, revert_router) {
    // const REVERT_ROUTER = JSON.stringify(revert_router);
    // localStorage.setItem('revertRouter', REVERT_ROUTER);
    commit('SET_REVERT_ROUTER', revert_router);
  },
  setCurrentUrl({ commit }, current_url) {
    const CURRENT_URL = JSON.stringify(current_url);
    localStorage.setItem('currentURL', CURRENT_URL);
    commit('SET_CURRENT_URL', current_url);
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
