var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { attrs: { prop: _vm.keyValidate, rules: _vm.rules, label: _vm.label } },
    [
      _vm._t("custom-label"),
      _vm._v(" "),
      _c("el-time-picker", {
        attrs: {
          type: _vm.type,
          size: _vm.size,
          format: _vm.format,
          placeholder: _vm.placeholder,
          "picker-options": _vm.pickerOptions,
          disabled: _vm.disabled,
        },
        on: { change: _vm.changeTime },
        model: {
          value: _vm.valueData,
          callback: function ($$v) {
            _vm.valueData = $$v
          },
          expression: "valueData",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }