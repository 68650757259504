var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-container" },
    [
      _c(
        "el-form-item",
        { attrs: { prop: _vm.keyValidate, rules: _vm.rules } },
        [
          _vm.label
            ? _c("label", { attrs: { for: _vm.inputId } }, [
                _vm._v(_vm._s(_vm.label)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-input", {
            class: _vm.classInput,
            attrs: {
              id: _vm.inputId,
              value: _vm.value,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              "show-password": _vm.isPassword,
              "max-length": _vm.maxLength,
              size: _vm.size,
            },
            on: { input: _vm.handleInput, change: _vm.handleChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }