import axios from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import store from '../store';
import i18n from '../lang';
import router from '../router/index';
import EventBus from '@/utils/eventBus';
import constAuth from '../const/auth';
import { MakeToast } from '../utils/toastMessage';
import { getLanguage } from '../utils/getLang';

import { getToken, destroyToken, destroyUserInfoCookie } from '@/utils/jwt';
// import Cookies from 'js-cookie';
import { API_NO_AUTH } from '@/const/config';
const baseURL = process.env.MIX_BASE_API;

const service = axios.create({
  baseURL: baseURL,
  timeout: 100000,
});

let checkShowToast = false;
const token = getToken();
const decodeToken = token ? jwt_decode(token) : {};
const currentTime = moment().format('X');
if (decodeToken && decodeToken.exp < Number(currentTime)) {
  destroyToken();
  destroyUserInfoCookie();
  window.location.href = '/login';
}

service.interceptors.request.use(
  config => {
    /* Format field  sort_by. Because it is not the same with element ui */
    if (config.method === 'get') {
      if (config.params && config.params.sort_by && config.params.sort_by.includes('ending')) {
        config.params.sort_by = config.params.sort_by === 'descending' ? 'desc' : 'asc';
      }
    }
    const token = getToken();
    config.headers['Accept-Language'] = getLanguage();
    if (token) {
      config.headers['Authorization'] = token;
    } else {
      if (!API_NO_AUTH.includes(config.url)) {
        router.push({ path: '/login' }).catch(() => {});
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
    const USER_NOT_FOUND_CODE = constAuth.USER_NOT_FOUND.code;
    if (USER_NOT_FOUND_CODE === response.data.code && !checkShowToast) {
      store.dispatch('user/doLogout')
        .then(() => {
          // MakeToast({
          //   variant: 'danger',
          //   title: i18n.t('DANGER'),
          //   content: i18n.t('LOGIN.SESSION_TIME_OUT'),
          // });
          checkShowToast = true;
          router.push('/login');
        })
        .catch(() => {
          checkShowToast = false;
          MakeToast({
            variant: 'danger',
            title: i18n.$t('DANGER'),
            content: i18n.$t('TOAST_HAVE_ERROR'),
          });
        });
    } else {
      return response;
    }
  },
  error => {
    const status = error.response.status;
    if (error.response.data.code && error.response.data.code === 403) {
      EventBus.$emit('responseErrorCode', error.response.data.code);
    }

    EventBus.$emit('responseStatusCode', status);
    return Promise.reject(error);
  }
);

export default service;
