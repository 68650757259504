<template>
  <div class="table-custom">
    <Loading :props-is-loading="loading" />
    <el-table
      v-show="!loading"
      :id="tableId"
      :data="tableData"
      :default-sort="defaultSort"
      :style="{
        width: tableWidth
      }"
      empty-text="データなし"
      @sort-change="(data) => $emit('sort-change', data)"
    >
      <slot name="slot-1" />
      <el-table-column
        v-for="(column, idx) in tableColumns"
        :key="idx"
        :prop="column.key"
        :label="column.title"
        :width="column.width ?? calculateWidthByPercentage(column.key)"
        :sortable="column.sortable"
      >
        <template slot-scope="scope">
          {{ scope.row[column.key] }}
          <i v-if="column.typeIcon === 'timer'" class="el-icon-time" />
        </template>
      </el-table-column>

      <slot name="slot-2" />
    </el-table>
  </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'VTable',
  components: {
    Loading,
  },
  props: {
    // configTable: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   },
    // },
    tableColumns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaultSort: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableWidth: {
      type: String,
      default: '100%',
    },
    tableId: {
      type: String,
      default: 'table-id',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTableWidth: 0,
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  created() {
    this.handleResize();
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      const element = document.querySelector(`#${this.tableId}`);
      if (element) {
        this.currentTableWidth = element.offsetWidth >= 700 ? element.offsetWidth : 700;
      }
    },
    calculateWidthByPercentage(key) {
      const targetCol = this.tableColumns.find((x) => x.key === key);
      if (!targetCol || !targetCol.widthPercentage) {
        return null;
      } else {
        return this.currentTableWidth * targetCol.widthPercentage / 100;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
</style>
