var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c(
        "el-select",
        {
          staticClass: "select-perpage",
          attrs: { size: "small", placeholder: "" },
          on: { change: _vm.changeSize },
          model: {
            value: _vm.perPage,
            callback: function ($$v) {
              _vm.perPage = $$v
            },
            expression: "perPage",
          },
        },
        _vm._l(_vm.listPerPage, function (item, idx) {
          return _c("el-option", {
            key: idx,
            attrs: { label: item, value: item },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("件表示")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }