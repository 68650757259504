import Cookies from 'js-cookie';

import { getLanguage } from '../../utils/getLang';

const state = {
  language: getLanguage(),
  loading: false,
  setOpen: false,
  changeDataInput: false,
  listToast: [],
  routeHistory: {},
};

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  CHANGE_DATA_INPUT: (state, changeData) => {
    state.changeDataInput = changeData;
  },
  SAVE_LIST_TOAST: (state, newList) => {
    state.listToast = newList;
  },
  SAVE_HISTORY_ROUTE: (state, routeObj) => {
    state.routeHistory = routeObj;
  },

};

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading);
  },
  setChangeData({ commit }, status) {
    commit('CHANGE_DATA_INPUT', status);
  },
  saveListToast({ commit }, newMsg) {
    commit('SAVE_LIST_TOAST', newMsg);
  },
  historyRouter({ commit }, data) {
    commit('SAVE_HISTORY_ROUTE', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
