import Vue from 'vue';
import App from './pages/App.vue';
import router from './router';
import './permission';
import store from './store';
import i18n from './lang';
import ElementUI from 'element-ui';
import CKEditor from 'ckeditor4-vue';
import HighchartsVue from 'highcharts-vue';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './scss/index.scss';
import Select2 from 'v-select2-component';
import Select2MultipleControl from 'v-select2-multiple-component';
import Bus from './utils/eventBus';

import * as registerComponent from '@/components/registerComponent';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ElementUI, { locale });
Vue.use(CKEditor);

Vue.directive('mode', {
  // Directive definition
});

/** Register global components */
Vue.component('Select2', Select2);
Vue.component('Select2MultipleControl', Select2MultipleControl);
Vue.use(HighchartsVue);

// Event bus
Vue.use(Bus);
Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  registerComponent,
  render: h => h(App),
});
