var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "b-list-group",
        _vm._l(_vm.navbars, function (item, index) {
          return _c(
            "b-list-group-item",
            {
              key: index,
              class: {
                selected: _vm.currentPath === item.path,
                "item-final":
                  index === _vm.navbars.length - 1 &&
                  _vm.userType === _vm.ROLE.TYPE_SUPER_ADMIN,
              },
            },
            [
              _c("router-link", { attrs: { to: item.path } }, [
                _c(
                  "span",
                  {
                    class: {
                      "pl-4": [
                        "/groups",
                        "/templates",
                        "/landing-pages",
                        "/sender-settings",
                      ].includes(item.path),
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
                ),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }