var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-custom" },
    [
      _c("select2-multiple-control", {
        class: {
          "invalid-select": _vm.invalidMessage,
        },
        attrs: {
          value: _vm.value,
          options: _vm.options,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
        },
        on: {
          "update:value": function ($event) {
            _vm.value = $event
          },
          change: (e) => _vm.changeEvent(e),
        },
      }),
      _vm._v(" "),
      _vm.invalidMessage
        ? _c("span", { staticClass: "text-red" }, [
            _vm._v(_vm._s(_vm.invalidMessage)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }