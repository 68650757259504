export default {
  VALIDATE: {
    REQUIRED_TEXT: '入力してください',
    REQUIRED_SELECT: '選択してください',
    FILE_UPLOAD_ERORR: '添付できるファイルは30MB以内のPDF, mp3, mp4のみです',
    FILE_CSV_UPLOAD_ERORR: '添付できるファイルは3MB以内のCSVのみです',
    FILE_EXEL_UPLOAD_ERORR: '添付できるファイルは3MB以内のEXELのみです',
    INPUT_GREATER_THAN_NUMBER: '{number}より大きいを入力してください。',
  },

  WARNING_MESS: {
    REQUIRED_FIELD_NOT_INPUT: '必須項目を入力してください',
    NOT_SELECT_DECLINE: '辞退理由を選択してください',
    CAN_NOT_ACCESS_FROM_THIS_ROUTER: '求人情報検索から操作して下さい',
  },

  // Router title
  ROUTER_LOGIN: 'ログイン',
  ROUTER_ERROR_PAGE: 'ページが見つかりません',
  ROUTER_USER_MANAGEMENT: 'ユーザー管理',
  ROUTER_USER_MANAGEMENT_LIST: 'ユーザー管理リスト',
  ROUTER_USER_MANAGEMENT_CREATE: 'ユーザー管理作成',
  ROUTER_USER_MANAGEMENT_DETAIL: 'ユーザー管理の詳細',
  ROUTER_USER_MANAGEMENT_EDIT: 'ユーザー管理編集',
  ROUTER_DEV: '開発者',

  // Breadcrumb
  BREADCRUMB_MASTER_MANAGEMENT: 'マスタ管理',
  BREADCRUMB_MASTER_MANAGEMENT_LIST: 'リスト',
  BREADCRUMB_MASTER_MANAGEMENT_CREATE: '作成',
  BREADCRUMB_MASTER_MANAGEMENT_DETAIL: '詳細',
  BREADCRUMB_MASTER_MANAGEMENT_EDIT: '編集',
  BREADCRUMB_USER_MANAGEMENT: 'ユーザー管理',
  BREADCRUMB_USER_MANAGEMENT_LIST: 'リスト',
  BREADCRUMB_USER_MANAGEMENT_CREATE: '作成',
  BREADCRUMB_USER_MANAGEMENT_DETAIL: '詳細',
  BREADCRUMB_USER_MANAGEMENT_EDIT: '編集',
  BREADCRUMB_ERROR_PAGE: 'エラーページ',
  BREADCRUMB_ERROR_PAGE_404: 'ページが見つかりません',
  BREADCRUMB_DEV: '開発者',
  BREADCRUMB_DEV_INDEX: '索引',

  // Right Menu
  MENU_RIGHT_LOGOUT: 'ログアウト',
  SUCCESS: '成功',
  WARNING: '警告',
  DANGER: 'エラー',
  TOAST_HAVE_ERROR: '予期しないエラーが発生しました',

  // Login
  LOGIN_TITLE: '海外人材マッチングシステム',
  EMAIL: 'Eメール',
  LOGIN_ID: 'ログインID',
  PASSWORD: 'パスワード',
  BUTTON_LOGIN: 'ログイン',
  LOGIN_SUCCESS: 'ログインしました',
  ERROR_VALIDATE_EMAIL_PASSWORD: 'メールアドレスまたはパスワードの形式をが正しくありません',
  IF_YOU_FORGET_YOUR_PASSWORD: 'パスワードを忘れた場合',

  // Month Picker
  MONTH_1: '01',
  MONTH_2: '02',
  MONTH_3: '03',
  MONTH_4: '04',
  MONTH_5: '05',
  MONTH_6: '06',
  MONTH_7: '07',
  MONTH_8: '08',
  MONTH_9: '09',
  MONTH_10: '10',
  MONTH_11: '11',
  MONTH_12: '12',

  // Return
  BUTTON_RETURN: '戻る',
  BUTTON_RETURN_TO_HOME: 'ホームに戻る',

  // Please Select
  PLEASE_SELECT_TEXT: '--- 選択してください ---',
  FILE_NOT_SELECTED: '選択されていません',

  // Please choose a file
  PLEASE_CHOOSE_FILE: 'ファイルを選択してください。',
  PLEASE_SELECT_DATA: '削除するデータを選択してください',

  // Text Button Control
  BUTTON_EDIT: '編集',
  BUTTON_DELETE: '削除',
  OK: 'OK',

  TABLE_EMPTY: 'データなし',

  // User Management Create
  USER_MANAGEMENT_CREATE_VALIDATE_FULL: '必須項目をすべて入力してください',
  USER_MANAGEMENT_CREATE_SUCCESS: 'ユーザー {name} を作成しました',
  USER_MANAGEMENT_DELETE_SUCCESS: 'ユーザー {name} を削除しました',
  USER_MANAGEMENT_CREATE_NAME_REQUIRED: '名前（必須）：',
  USER_MANAGEMENT_CREATE_EMAIL_REQUIRED: 'メールアドレス（必須）：',
  USER_MANAGEMENT_CREATE_PASSWORD_REQUIRED: 'パスワード（必須）：',
  USER_MANAGEMENT_CREATE_AUTHORITY_REQUIRED: '権限（必須）：',
  USER_MANAGEMENT_CREATE_BUTTON_SIGN_UP: '新規登録',
  USER_MANAGEMENT_EDIT_PASSWORD_UPDATE: 'パスワード：（入力した場合だけ更新されます）',
  USER_MANAGEMENT_EDIT_BUTTON_SAVE: '編集保存',
  USER_MANAGEMENT_EDIT_SUCCESS: 'ユーザー {name} 名を編集しました',

  // Button
  SIGN_UP: '新規登録',
  SELECT_DELETE: ' 選択削除',
  SEARCH: '検索',
  DISPLAY: '表示',
  EDIT: '編集',
  DELETE: '削除',

  // User Management List
  USER_MANAGEMENT_NAME: '名前',
  USER_MANAGEMENT_MAIL: 'メールアドレス',
  USER_MANAGEMENT_AUTH: '権限',
  CONTENT_SUSS: 'ユーザーを削除しました',
  isMessage: '選択されていません。',

  // Master Management Display 1
  MASTER_MANAGEMENT_DISPLAY_DELETE: '削除',
  MASTER_MANAGEMENT_DISPLAY_DELETE_MESS: ' {name} ',
  MASTER_MANAGEMENT_CREATE_VALIDATE_FULL: '必須項目をすべて入力してください ',
  MASTER_MANAGEMENT_CREATE_SUCCESS: '{name} を作成しました',
  MASTER_MANAGEMENT_DELETE_SUCCESS: 'アイテム {name} を削除しました',
  MASTER_MANAGEMENT_EDIT_SUCCESS: '{name} 名を編集しました',
  MASTER_MANAGEMENT_ADD_DUPLICATE: '追加したデータが重複しています',

  BUTTON: {
    UPGRADE: '今すぐアップグレード',
    SEE_DETAIL: '詳細を見る',
    RETURN: '戻る',
    NEXT_BTN: '次へ',
    GO_TO_LOGIN_SCREEN: 'ログイン画面へ',
    BUTTON_LOGIN: 'ログイン',
    BACK_TO_PREVIOUS_SCREEN: '前の画面へ戻る',
    DELETE_ALL: 'チェックしたものを一括削除',
    BACK_TO_LIST: '一覧に戻る',
    SAVE: '保存',
    CANCEL: 'キャンセル',
    EDIT: '編集',
    DELETE: '削除する',
    DETAIL: '詳細',
    SHOW_DETAIL: '詳細',
  },

  VALIDATE_DATA: {
    REQUIRED_TEXT: '入力してください',
    REQUIRED_SELECT: '選択してください',
    REQUIRED: '必須',
    ARBITRARY: '任意',
    FILE_UPLOAD_ERORR: '添付できるファイルは30MB以内のPDF, mp3, mp4のみです',
  },

  LOGIN: {
    LOGIN_ID: 'ログインID',
    PASSWORD: 'パスワード',
    ERROR_VALIDATE_EMAIL_PASSWORD: 'メールアドレスまたはパスワードの形式をが正しくありません',
    IF_YOU_FORGET_YOUR_PASSWORD: 'パスワードを忘れた場合',
    SESSION_TIME_OUT: 'SESSION_TIME_OUT',
    INSTRUCTION: 'ユーザー契約に同意した後、ログインしてください。',
    FORGOT_PASSWORD: 'パスワードをお忘れですか?',
    RESET_PASSWORD: 'パスワードのリセットをリクエストしてください。',
  },

  NOT_FOUND: {
    TITLE: '404 エラー!',
    THE_PAGE_DOESNT_EXIST: 'お探しのページは存在しません',
  },
  // Message success create, edit group
  CREATE_GROUP_SUCCESS: '成功しました。',

};
