<template>
  <div class="input-container">
    <el-form-item
      :prop="keyValidate"
      :rules="rules"
    >
      <label :for="inputId">{{ label }}</label>
      <el-input :id="inputId" :value="value" :placeholder="placeholder" :disabled="disabled" @input="handleInput" @change="handleChange" />
    </el-form-item>
  </div>
</template>

<script>

export default {
  name: 'NormalInput',
  components: {
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    keyValidate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      inputModel: '',
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  watch: {
    activeTab(newVal, oldVal) {
    },
  },
  created() {
  },
  methods: {
    handleInput(value) {
      // Emit the input event for v-model to update the parent data
      const numbersOnly = value.replace(/[^\d]/g, '');
      this.$emit('input', numbersOnly);
      this.$emit('update:value', numbersOnly);
    },
    handleChange(value) {
      // Emit the input event for v-model to update the parent data
      this.$emit('change', value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
