<template>
  <modal-notification
    :icon-type="confirmIconHtml || 'question'"
    :show-modal="showModal"
    @handleCloseDialog="handleCloseDialog"
  >
    <template slot="modalBody">
      <div v-html="confirmIconHtml" />
      <div v-html="confirmTitle" />
      <div v-html="confirmContent" />
    </template>
    <template slot="modalFooter">
      <el-button type="info" @click="confirm(false)">キャンセル</el-button>
      <el-button type="primary" @click="confirm(true)">
        {{ confirmBtnTxt }}
      </el-button>
    </template>
  </modal-notification>
</template>

<script>
import ModalNotification from '@/components/Modal/ModalNotification';
export default {
  name: 'ModalConfirm',
  components: {
    ModalNotification,
  },
  props: {
    confirmIconHtml: {
      type: String,
      default: '',
    },
    confirmTitle: {
      type: String,
      default: '',
    },
    confirmContent: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    confirmBtnTxt: {
      type: String,
      default: '登録',
    },
  },
  data() {
    return {
    };
  },

  methods: {
    confirm(status) {
      this.$emit('confirm', status);
    },
    handleCloseDialog(){
      this.$emit('handleCloseDialog');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
