<template>
  <div class="search-item d-flex align-items-center">
    <span class="mr-2 text-nowrap">検索</span>
    <el-input
      :value="value"
      size="small"
      :placeholder="placeholder"
      suffix-icon="el-icon-search"
      clearable
      @input="handleInput"
      @change="handleChange"
      @keyup.enter.native="$emit('pressEnter')"
      @clear="$emit('clear')"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchIpunt',
  components: {
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  created() {
  },
  methods: {
    handleInput(e) {
      // Emit the input event for v-model to update the parent data
      this.$emit('input', e);
    },
    handleChange(value) {
      // Emit the input event for v-model to update the parent data
      this.$emit('change', value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
