import axios from '@/utils/axios';

export const getListLandingPages = async(params = {}) => {
  return await axios.get('/landing-page', { params });
};

export const deleteLandingPageById = async(id = {}) => {
  return await axios.delete(`/landing-page/${id}`);
};

export const addNewLandingPages = async(params = {}) => {
  return await axios.post('/landing-page', params);
};

export const getDetailLandingPageById = async(id = {}) => {
  return await axios.get(`/landing-page/${id}`);
};

export const editLandingPage = async(id, params) => {
  return await axios.put(`/landing-page/${id}`, params);
};

export const showDetail = async(id) => {
  return await axios.get(`/landing-page/${id}`);
};

export const getMenu = async() => {
  return await axios.get('/page/getMenu');
};

export const showPage = async(uid) => {
  return await fetch(`/api/landing-page/page/${uid}`);
};
