<template>
  <el-form-item :prop="keyValidate" :rules="rules" :label="label">
    <slot name="custom-label" />
    <el-date-picker
      v-model="valueData"
      :type="type"
      :size="size"
      :format="format"
      :placeholder="placeholder"
      :picker-options="pickerOptions"
      :disabled="disabled"
      @change="changeDate"
    />
  </el-form-item>
</template>

<script>
import moment from 'moment';
export default {
  name: 'VDatePicker',
  components: {
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    type: {
      type: String,
      default: 'date',
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keyValidate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valueData: null,
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
    pickerOptions() {
      const _this = this;
      return {

        disabledDate(time) {
          // Disable dates before minDate and after maxDate
          if (_this.minDate || _this.maxDate) {
            return time.getTime() < new Date(_this.minDate).getTime() || time.getTime() > new Date(_this.maxDate).getTime();
          }
          return false;
        },
      };
    },
  },
  watch: {
    value: {
      handler: function() {
        this.valueData = this.value;
      },
      deep: true,
    },
  },
  created() {
    this.valueData = this.value;
  },
  methods: {
    changeDate(val) {
      // if(val === 'Invalid date')
      const newVal = val ? moment(val).format('YYYY-MM-DD') : null;
      this.$emit('input', newVal);
      this.$emit('update:value', newVal);
      this.$emit('change', newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
