<template>
  <div
    v-show="propsIsLoading"
    class="site-loading loading"
    dusk="loading"
  >
    <div class="loader" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    propsIsLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  // watch: {
  //   propsIsLoading(value) {
  //     this.isLoading = value;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import '@/scss/loading.scss';
</style>
