<template>
  <div class="sidebar">
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in navbars"
        :key="index"
        :class="{
          selected: currentPath === item.path,
          'item-final': index === navbars.length - 1 && userType === ROLE.TYPE_SUPER_ADMIN
        }"
      >
        <router-link :to="item.path">
          <span
            :class="{
              'pl-4': [
                '/groups',
                '/templates',
                '/landing-pages',
                '/sender-settings',
              ].includes(item.path),
            }"
          >
            {{ item.title }}
          </span>
        </router-link>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { getUserInfo } from '../../utils/getProfile';
import ROLE from '@/const/role';
export default {
  name: 'Sidebar',
  components: {},

  data() {
    return {
      ROLE: ROLE,
      // navbars: [
      //   { path: '/', title: 'ホーム' },
      //   { path: '/gallery', title: '訓練シナリオギャラリー' },
      //   { path: '/campaigns', title: '訓 練' },
      //   { path: '/groups', title: '配信リスト' },
      //   { path: '/templates', title: 'メール・添付' },
      //   { path: '/landing-pages', title: 'リンク先画面' },
      //   { path: '/sender-settings', title: '送信元設定' },
      //   { path: '/users', title: 'ユーザー管理' },
      // ],
    };
  },
  computed: {
    userType() {
      const userInfo = getUserInfo();
      return userInfo.type;
    },
    navbars() {
      return this.user_type === ROLE.TYPE_SUPER_ADMIN
        ? [
          { path: '/', title: 'ホーム' },
          { path: '/gallery', title: '訓練シナリオギャラリー' },
          { path: '/campaigns', title: '訓 練' },
          { path: '/groups', title: '配信リスト' },
          { path: '/templates', title: 'メール・添付' },
          { path: '/landing-pages', title: 'リンク先画面' },
          { path: '/sender-settings', title: '送信元設定' },
          { path: '/users', title: 'ユーザー管理' },
        ]
        : [
          { path: '/', title: 'ホーム' },
          { path: '/gallery', title: '訓練シナリオギャラリー' },
          { path: '/campaigns', title: '訓 練' },
          { path: '/groups', title: '配信リスト' },
          { path: '/templates', title: 'メール・添付' },
          { path: '/landing-pages', title: 'リンク先画面' },
          { path: '/sender-settings', title: '送信元設定' },
        ];
    },
    currentPath() {
      return this.$route.path;
    },
  },
  created() {
    const userInfo = getUserInfo();
    this.user_type = userInfo.type;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.sidebar {
  position: fixed;
  width: 16%;
  top: 66px;
  bottom: 0;
  left: 0;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding-top: 1rem;
  border-right: 1px solid #eee;
}

.list-group-item {
  background-color: transparent;
  border: 0;
  padding: 0;

  a {
    display: block;
    padding: 0.75rem 1.25rem;
    color: #000;
    text-decoration: none;
    &:hover {
      // background-color: #283f4f;
      background-color: $headerColor;
      color: #fff;
    }
  }
  &.selected {
    a {
      // background-color: #283f4f;
      background-color: $headerColor;
      color: #fff;
    }
  }
}

.item-final {
  border-top: 1px solid #eee !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
