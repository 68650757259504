import Layout from '@/layout';

const TemplateEmail = {
  path: '/templates',
  name: 'TemplateEmailManagement',
  meta: {
    title: 'Template Email data',
    breadcrumb: '',
  },
  component: Layout,
  redirect: { name: 'TemplateEmailManagement' },
  children: [
    {
      path: '',
      name: 'TemplateEmail',
      meta: {
        title: 'メール・添付',
        breadcrumb: 'メール・添付',
      },
      component: () => import('@/pages/TemplateEmail/index.vue'),
    },
  ],
};

export default TemplateEmail;

