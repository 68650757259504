const getters = {
  role: state => state.user.role,
  etc: state => state.etc.etcOrigin,
  loading: state => state.app.loading,
  language: state => state.app.language,
  profile: state => state.user.profile,
  name: state => state.user.profile.name,
  token: state => state.user.access_token,
  email: state => state.user.profile.email,
  routes: state => state.permission.routes,
  permission: state => state.user.permission,
  permissionCheck: state => state.user.profile.type,
  permissionRoutes: state => state.permission.routes,
  currentRoutes: state => state.permission.currentRoutes,
  revertRouter: state => state.routerParam.revertRouter,
  currentUrl: state => state.routerParam.currentURL,
  listMessToast: state => state.app.listToast,
};

export default getters;
