<template>
  <div class="top-bar">
    <nav class="navbar app-navbar">
      <a class="navbar-brand d-flex" href="#">
        <img class="navbar-logo" :src="require('@/assets/images/icons/veho_icon.png')">
        <h4 class="veho-title">V-Mail</h4>
      </a>
      <b-navbar-toggle target="nav-collapse" />
      <div class="d-flex justify-content-end align-items-center">
        <!-- <div class="d-flex text-white text-sm align-items-center">
          <b-icon icon="bell-fill" />
          <span class="text-xs mx-2">お知らせ</span>
        </div> -->
        <b-button size="md" class="d-flex align-items-center green-color">
          <b-icon icon="person-fill" aria-hidden="true" /> {{ email }}
        </b-button>
        <b-button size="md" class="d-flex align-items-center green-color height-logout" @click="handleLogout">
          <b-icon icon="power" aria-hidden="true" />
        </b-button>
      </div>
    </nav>
  </div>
</template>

<script>
import { getUserInfo } from '../../utils/getProfile';

export default {
  name: 'Header',
  components: {},

  data() {
    return {
      email: '',
    };
  },
  created(){
    const userInfo = getUserInfo();
    this.email = userInfo?.email ?? '';
  },

  methods: {
    handleLogout() {
      this.$emit('handleLogout');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.top-bar {
  background-color: #2f4154;
  color: #fff;
}

.user-info {
  float: right;
}

.username {
  margin-right: 10px;
}

.green-color {
  background-color: #18BC9C !important;
}

.height-logout {
  height: 38px;
}
.app-navbar {
  position: fixed !important;
  line-height: 2;
  top: 0px;
  /* background-color: #2f4154; */
  background-color: $headerColor;
  color: #fff;
  width: 100%;
  z-index: 2;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 4px;
}

.navbar-logo {
  width: 40px;
}

.veho-title {
  color: #fff;
  font-weight: bold;
  margin-bottom: 0;
  position: relative;
  right: 8px;
}
</style>
