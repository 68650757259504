<template>
  <el-form-item :prop="keyValidate" :rules="rules" :label="label">
    <slot name="custom-label" />
    <el-time-picker
      v-model="valueData"
      :type="type"
      :size="size"
      :format="format"
      :placeholder="placeholder"
      :picker-options="pickerOptions"
      :disabled="disabled"
      @change="changeTime"
    />
  </el-form-item>
</template>

<script>
import moment from 'moment';
export default {
  name: 'VTimePicker',
  components: {
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    type: {
      type: String,
      default: 'date',
    },
    format: {
      type: String,
      default: 'HH:mm',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    keyValidate: {
      type: String,
      default: '',
    },
    pickerOptions: {
      type: Object,
      default: () => {
        return { format: 'HH:mm' };
      },
    },
  },
  data() {
    return {
      valueData: '',
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  watch: {

    value: {
      handler: function() {
        this.convertVmodel();
      },
      deep: true,
    },
  },
  created() {
    this.convertVmodel();
  },
  methods: {
    convertVmodel() {
      const today = new Date();
      if (!this.value){
        this.valueData = null;
        return;
      }
      const seprateNewVal = this.value.split(':');
      this.valueData = new Date(today.getFullYear(), today.getMonth(), today.getDate(), Number(seprateNewVal[0]), Number(seprateNewVal[1]));
    },
    changeTime(val) {
      // if(val === 'Invalid date')
      const newVal = val ? moment(val).format('HH:mm') : null;
      this.$emit('input', newVal);
      this.$emit('update:value', newVal);
      this.$emit('change', newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
