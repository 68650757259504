import CustomPagination from '@/components/pagination/index.vue';
import ChangeSize from '@/components/pagination/ChangeSize.vue';
import NormalInput from '@/components/Input/NormalInput.vue';
import ModalConfirmDelete from '@/components/Modal/types/ModalConfirmDelete.vue';
import ModalSuccess from '@/components/Modal/types/ModalSuccess.vue';
import InputNumber from '@/components/Input/InputNumber.vue';
import VSelect2 from '@/components/Select/VSelect2.vue';
import VSelectMultiple from '@/components/Select/VSelectMultiple.vue';
import NormalSelect from '@/components/Select/NormalSelect.vue';
import VTable from '@/components/table/vTable.vue';
import ModalConfirm from '@/components/Modal/types/ModalConfirm.vue';
import VDatePicker from '@/components/DatePicker/VDatePicker.vue';
import VTimePicker from '@/components/DatePicker/VTimePicker.vue';
import SearchInput from '@/components/Search/SearchInput.vue';
import Vue from 'vue';
Vue.component('CustomPagination', CustomPagination);
Vue.component('ChangeSize', ChangeSize);
Vue.component('NormalInput', NormalInput);
Vue.component('ModalConfirmDelete', ModalConfirmDelete);
Vue.component('ModalSuccess', ModalSuccess);
Vue.component('InputNumber', InputNumber);
Vue.component('VTable', VTable);
Vue.component('VSelectMultiple', VSelectMultiple);
Vue.component('VSelect2', VSelect2);
Vue.component('NormalSelect', NormalSelect);
Vue.component('ModalConfirm', ModalConfirm);
Vue.component('VDatePicker', VDatePicker);
Vue.component('VTimePicker', VTimePicker);
Vue.component('SearchInput', SearchInput);
