<template>
  <div id="app">
    <router-view />
    <!-- <router-view v-if="!layout" /> -->
    <!-- <NotFound v-if="layout" /> -->
  </div>
</template>

<script>

// import NotFound from '@/layout/components//NotFound.vue';

export default {
  name: 'App',
  components: {
    // NotFound,
  },
  computed: {
    layout() {
      // not exist status404 in getters
      return this.$store.getters.status404;
    },
  },
  created() {
    // console.log('%Spam Email', 'font-size: 20px; padding: 5px 10px 5px 10px; border-radius: .25rem; color: #FFFFF; background-color: #FB9A09; text-align: center;');
  },
  mounted() {
    this.$store.dispatch('permission/generateRoutes', { roles: [], permissions: [] })
      .then((routes) => {
        for (let route = 0; route < routes.length; route++) {
          this.$router.addRoute(routes[route]);
        }
      });
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}

</style>

