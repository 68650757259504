var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "pagination-container d-flex align-items-center justify-content-between",
    },
    [
      _c("div", { staticClass: "pagination-details" }, [
        _c("div", { staticClass: "record-info mr-3" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                `${_vm.totalRows}件中 ${_vm.startRecord} から ${_vm.endRecord} まで表示`
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-more-per-page" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "change-page" }, [
        _c(
          "div",
          { staticClass: "ml-3" },
          [
            _c("b-pagination", {
              staticClass: "w-100",
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                pills: "",
                "next-class": "next",
                "prev-class": "prev",
              },
              on: { change: ($event) => _vm.changePagination($event) },
              model: {
                value: _vm.pageNumber,
                callback: function ($$v) {
                  _vm.pageNumber = $$v
                },
                expression: "pageNumber",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }