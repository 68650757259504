var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.propsIsLoading,
          expression: "propsIsLoading",
        },
      ],
      staticClass: "site-loading loading",
      attrs: { dusk: "loading" },
    },
    [_c("div", { staticClass: "loader" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }