import Layout from '@/layout';
import ROLE from '@/const/role.js';

const homeManagement = {
  path: '',
  name: 'HomeManagement',
  meta: {
    title: 'TAB_HOME_MANAGEMENT',
    breadcrumb: 'BREADCRUMB_HOME_MANAGEMENT',
    role: [ROLE.TYPE_SUPER_ADMIN, ROLE.TYPE_USER],
  },
  component: Layout,
  redirect: { name: 'HomeManagement' },
  children: [
    {
      path: '',
      name: 'Home',
      meta: {
        title: 'ROUTER_COMPANY_MANAGEMENT_LIST',
        breadcrumb: 'BREADCRUMB_COMPANY_MANAGEMENT_LIST',
        role: [ROLE.TYPE_SUPER_ADMIN, ROLE.TYPE_USER],
      },
      component: () => import('@/pages/Home/index.vue'),
    },
  ],
};

export default homeManagement;

