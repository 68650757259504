<template>
  <el-dialog
    class="modal-notify"
    width="35%"
    :show-close="false"
    :visible.sync="show"
    append-to-body
    :close-on-click-modal="false"
    @close="handleCloseDialog"
  >
    <div class="text-center">
      <div class="text-8xl">
        <i v-if="iconType === 'warning'" class="el-icon-warning-outline" />
        <i v-if="iconType === 'question'" class="el-icon-question" />
        <i v-if="iconType === 'success'" class="el-icon-circle-check" />
      </div>
      <slot name="modalBody" />
    </div>
    <span slot="footer" class="dialog-footer d-flex justify-content-center">
      <slot name="modalFooter" />
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'ModalNotification',
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    iconType: {
      // warning - question - success
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  watch: {
    showModal: {
      handler(newValue) {
        this.show = newValue;
      },
    },
  },

  created() {
  },
  methods: {
    handleCloseDialog(){
      this.$emit('handleCloseDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.el-icon-warning-outline {
  color: $warning;
}
.el-icon-question {
  color: $question;
}
.el-icon-circle-check {
  color: $success;
}
</style>
