import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import message from './modules/message';
import user from './modules/user';
import permission from './modules/permission';
import routerParam from './modules/routerParam';

import getters from './getters';

Vue.use(Vuex);

const modules = {
  app,
  message,
  user,
  permission,
  routerParam,
};

const store = new Vuex.Store({
  modules,
  getters,
});

export default store;
