export default {
  VALIDATE: {
    REQUIRED_TEXT: 'Please input',
    REQUIRED_SELECT: 'Please select',
    FILE_UPLOAD_ERORR: 'The only files that can be attached are PDF, mp3, mp4 within 30MB',
    FILE_CSV_UPLOAD_ERORR: 'Only CSV files up to 3MB can be attached',
    FILE_EXEL_UPLOAD_ERORR: 'Only EXCEL files up to 3MB can be attached',
    INPUT_GREATER_THAN_NUMBER: 'Enter a number greater than {number}',
  },

  WARNING_MESS: {
    REQUIRED_FIELD_NOT_INPUT: 'Please enter the required fields',
    NOT_SELECT_DECLINE: 'Please select the reason for decline',
    CAN_NOT_ACCESS_FROM_THIS_ROUTER: 'Please operate from job information search.',
  },

  // Router title
  ROUTER_LOGIN: 'Login',
  ROUTER_ERROR_PAGE: 'Page Not Found',
  ROUTER_USER_MANAGEMENT: 'USER MANAGEMENT',
  ROUTER_USER_MANAGEMENT_LIST: 'User Management List',
  ROUTER_USER_MANAGEMENT_CREATE: 'User Management Create',
  ROUTER_USER_MANAGEMENT_DETAIL: 'User Management Detail',
  ROUTER_USER_MANAGEMENT_EDIT: 'User Management Edit',
  ROUTER_DEV: 'Dev',

  // Breadcrumb
  BREADCRUMB_MASTER_MANAGEMENT: 'Master Management',
  BREADCRUMB_MASTER_MANAGEMENT_LIST: 'List',
  BREADCRUMB_MASTER_MANAGEMENT_CREATE: 'Create',
  BREADCRUMB_MASTER_MANAGEMENT_DETAIL: 'Detail',
  BREADCRUMB_MASTER_MANAGEMENT_EDIT: 'Edit',
  BREADCRUMB_USER_MANAGEMENT: 'User Management',
  BREADCRUMB_USER_MANAGEMENT_LIST: 'List',
  BREADCRUMB_USER_MANAGEMENT_CREATE: 'Create',
  BREADCRUMB_USER_MANAGEMENT_DETAIL: 'Detail',
  BREADCRUMB_USER_MANAGEMENT_EDIT: 'Edit',
  BREADCRUMB_ERROR_PAGE: 'Error Page',
  BREADCRUMB_ERROR_PAGE_404: 'Page Not Found',
  BREADCRUMB_DEV: 'Dev',
  BREADCRUMB_DEV_INDEX: 'Index',

  // Right Menu
  MENU_RIGHT_LOGOUT: 'Logout',
  SUCCESS: 'Success',
  WARNING: 'Warning',
  DANGER: 'Danger',
  TOAST_HAVE_ERROR: 'An unexpected error has occurred',

  // Login
  LOGIN_TITLE: 'Oversea HR matching system',
  EMAIL: 'Email',
  LOGIN_ID: 'Login ID',
  PASSWORD: 'Password',
  BUTTON_LOGIN: 'Login',
  LOGIN_SUCCESS: 'Logged in successfully',
  ERROR_VALIDATE_EMAIL_PASSWORD: 'You entered the wrong format of Email, Password',
  IF_YOU_FORGET_YOUR_PASSWORD: 'If you forget your password',

  // Month Picker
  MONTH_1: '01',
  MONTH_2: '02',
  MONTH_3: '03',
  MONTH_4: '04',
  MONTH_5: '05',
  MONTH_6: '06',
  MONTH_7: '07',
  MONTH_8: '08',
  MONTH_9: '09',
  MONTH_10: '10',
  MONTH_11: '11',
  MONTH_12: '12',

  // Return
  BUTTON_RETURN: 'BACK',
  BUTTON_RETURN_TO_HOME: 'Back to Home',

  // Please Select
  PLEASE_SELECT_TEXT: '--- Please select an option ---',
  FILE_NOT_SELECTED: 'File not selected',

  // Please choose a file
  PLEASE_CHOOSE_FILE: 'Please choose a file.',
  PLEASE_SELECT_DATA: 'Select data to delete.',

  // Text Button Control
  BUTTON_EDIT: 'Edit',
  BUTTON_DELETE: 'Delete',
  OK: 'OK',

  TABLE_EMPTY: 'No data',

  // User Management Create
  USER_MANAGEMENT_CREATE_VALIDATE_FULL: 'You have not entered all the information!',
  USER_MANAGEMENT_CREATE_SUCCESS: 'User {name} has been created successfully!',
  USER_MANAGEMENT_DELETE_SUCCESS: 'User {name} has been deleted successfully!',
  USER_MANAGEMENT_CREATE_NAME_REQUIRED: 'Name (Required):',
  USER_MANAGEMENT_CREATE_EMAIL_REQUIRED: 'Mail address (Required):',
  USER_MANAGEMENT_CREATE_PASSWORD_REQUIRED: 'Password (Required):',
  USER_MANAGEMENT_CREATE_AUTHORITY_REQUIRED: 'Authority (Required):',
  USER_MANAGEMENT_CREATE_BUTTON_SIGN_UP: 'Sign up',
  USER_MANAGEMENT_EDIT_PASSWORD_UPDATE: 'Password (Update only if entered):',
  USER_MANAGEMENT_EDIT_BUTTON_SAVE: 'Edit and save',
  USER_MANAGEMENT_EDIT_SUCCESS: 'User {name} has been edited successfully!',

  // Button
  SIGN_UP: 'Sign up',
  SELECT_DELETE: 'Select delete',
  SEARCH: 'Search',
  DISPLAY: 'Display',
  EDIT: 'Edit',
  DELETE: 'Delete',

  // User Management List
  USER_MANAGEMENT_NAME: 'Name',
  USER_MANAGEMENT_MAIL: 'Mail address',
  USER_MANAGEMENT_AUTH: 'Authority',
  CONTENT_SUSS: 'Delete User Successfull !',
  isMessage: 'Not selected',

  // Master Management Display 1
  MASTER_MANAGEMENT_DISPLAY_DELETE: 'Delete',
  MASTER_MANAGEMENT_DISPLAY_DELETE_MESS: ' {name} ',
  MASTER_MANAGEMENT_CREATE_VALIDATE_FULL: 'You have not entered all the information!',
  MASTER_MANAGEMENT_CREATE_SUCCESS: 'Item {name} has been created successfully!',
  MASTER_MANAGEMENT_DELETE_SUCCESS: 'Item {name} has been deleted successfully!',
  MASTER_MANAGEMENT_EDIT_SUCCESS: 'Item {name} has been edited successfully!',
  MASTER_MANAGEMENT_ADD_DUPLICATE: 'This center code {name} has been used!',

  BUTTON: {
    UPGRADE: 'Upgrade',
    SEE_DETAIL: 'See detail',
    RETURN: 'Back',
    NEXT_BTN: 'Next',
    GO_TO_LOGIN_SCREEN: 'Go to login screen',
    BUTTON_LOGIN: 'Login',
    BACK_TO_PREVIOUS_SCREEN: 'Back to previous screen',
    DELETE_ALL: 'Batch delete checked items',
    BACK_TO_LIST: 'Back to list',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    EDIT: 'Edit',
    DELETE: 'Delete',
    DETAIL: 'Detail',
    SHOW_DETAIL: 'Detail',
  },

  VALIDATE_DATA: {
    REQUIRED_TEXT: 'Please input',
    REQUIRED_SELECT: 'Please select',
    REQUIRED: 'Required',
    ARBITRARY: 'Arbitrary',
    FILE_UPLOAD_ERORR: 'The only files that can be attached are PDF, mp3, mp4 within 30MB.',
  },

  LOGIN: {
    LOGIN_ID: 'Login ID',
    PASSWORD: 'Password',
    ERROR_VALIDATE_EMAIL_PASSWORD: 'You entered the wrong format of Email, Password',
    IF_YOU_FORGET_YOUR_PASSWORD: 'If you forget your password',
    SESSION_TIME_OUT: 'SESSION_TIME_OUT',
    INSTRUCTION: 'Please log in after agreeing to the user agreement',
    FORGOT_PASSWORD: 'Forgot password?',
    RESET_PASSWORD: 'Password Reset Request',
  },

  NOT_FOUND: {
    TITLE: '404 ERROR!',
    THE_PAGE_DOESNT_EXIST: `The page you’re looking for doesn’t exist`,
  },

  // Message success Group
  CREATE_GROUP_SUCCESS: 'success',

};
