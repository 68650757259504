import Layout from '@/layout';

const campaignManagement = {
  path: '/campaigns',
  name: 'campaignManagement',
  meta: {
    title: 'TAB_HOME_MANAGEMENT',
    breadcrumb: 'BREADCRUMB_HOME_MANAGEMENT',
  },
  component: Layout,
  redirect: { name: 'campaignManagement' },
  children: [
    {
      path: '',
      name: 'Campaigns',
      meta: {
        title: 'ROUTER_COMPANY_MANAGEMENT_LIST',
        breadcrumb: 'BREADCRUMB_COMPANY_MANAGEMENT_LIST',
      },
      component: () => import('@/pages/Campaigns/index.vue'),
    },
    {
      path: ':id',
      name: 'CampaignDetail',
      meta: {
        title: 'ROUTER_HR_MANAGEMENT_DETAIL',
        breadcrumb: 'BREADCRUMB_HR_MANAGEMENT_DETAIL',
      },
      component: () => import('../../pages/Campaigns/detail'),
    },
  ],
};

export default campaignManagement;

