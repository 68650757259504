var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-custom" },
    [
      _c("Loading", { attrs: { "props-is-loading": _vm.loading } }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          style: {
            width: _vm.tableWidth,
          },
          attrs: {
            id: _vm.tableId,
            data: _vm.tableData,
            "default-sort": _vm.defaultSort,
            "empty-text": "データなし",
          },
          on: { "sort-change": (data) => _vm.$emit("sort-change", data) },
        },
        [
          _vm._t("slot-1"),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function (column, idx) {
            return _c("el-table-column", {
              key: idx,
              attrs: {
                prop: column.key,
                label: column.title,
                width:
                  column.width ?? _vm.calculateWidthByPercentage(column.key),
                sortable: column.sortable,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(scope.row[column.key]) +
                            "\n        "
                        ),
                        column.typeIcon === "timer"
                          ? _c("i", { staticClass: "el-icon-time" })
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm._t("slot-2"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }