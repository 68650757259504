import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import home from './modules/home.js';
import campaigns from './modules/campaigns.js';
import gallery from './modules/gallery.js';
import groups from './modules/groups.js';
import landingPages from './modules/landingPages.js';
import changePassword from './modules/changePassword.js';
import TemplateEmail from '@/router/modules/TemplateEmail.js';
import SenderSetting from '@/router/modules/SenderSetting.js';

import users from '@/router/modules/users.js';

// const listLandingPage = getMenuPage();
// console.log('day la list menu page', listLandingPage);
// const newRouter = [];
// for (const x of listLandingPage){
//   newRouter.push({
//     path: x,
//     name: 'Landing Page',
//     component: () => import('@/pages/Page/landing.vue'),
//   })
// }
export const constantRoutes = [
  // ...newRouter,
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/pages/Login/index.vue'),
    meta: {
      title: 'ROUTER_LOGIN',
    },
    hidden: true,
  },
  // Link follow gmail from BE
  {
    path: '/forget-password',
    name: 'ForgotPassword',
    component: () => import('@/pages/PassWords/ForgetPassWord/index.vue'),
    meta: {
      title: 'RESET_PASS_WORD_TITLE',
    },
    hidden: true,
  },
  {
    path: '/api/auth/password-reset',
    name: 'Reset password confirm',
    component: () => import('@/pages/PassWords/ResetPassWord/index.vue'),
    meta: {
      title: 'RESET_PASS_WORD_TITLE',
    },
    hidden: true,
  },
  {
    path: '/m-undelivered',
    name: 'EmailUndelivered',
    component: () => import('@/pages/PassWords/ForgetPassWord/MailUndelivered.vue'),
    meta: {
      title: 'EmailUndelivered',
    },
    hidden: true,
  },
];

export const asyncRoutes = [
  home,
  campaigns,
  gallery,
  groups,
  landingPages,
  changePassword,
  TemplateEmail,
  SenderSetting,
  users,
  {
    path: '*',
    name: 'Not Found',
    component: () => import('@/layout/components/NotFound.vue'),
    hidden: true,
  },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

const router = createRouter();
// router.addRoute(getMenuPage());
export default router;
