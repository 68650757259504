<template>
  <modal-notification
    icon-type="success"
    :show-modal="showModal"
    @handleCloseDialog="handleCloseDialog"
  >
    <template slot="modalBody">
      <h2 v-if="type === SUCCESS_TYPE.SAVE" class="text-bold">登録されました</h2>
      <h2 v-if="type === SUCCESS_TYPE.DELETE" class="text-bold">削除しました</h2>
      <div v-html="successHtml" />
    </template>
    <template slot="modalFooter">
      <el-button type="primary" @click="closeModal">
        OK
      </el-button>
    </template>
  </modal-notification>
</template>

<script>
import ModalNotification from '@/components/Modal/ModalNotification';
import { SUCCESS_TYPE } from '@/const/config.js';

export default {
  name: 'ModalSuccess',
  components: {
    ModalNotification,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    successHtml: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      SUCCESS_TYPE,
    };
  },

  methods: {
    closeModal(status) {
      this.$emit('close-modal', status);
    },
    handleCloseDialog(){
      this.$emit('handleCloseDialog');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
