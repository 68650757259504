var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-notification",
    {
      attrs: { "icon-type": "warning", "show-modal": _vm.showModal },
      on: { handleCloseDialog: _vm.handleCloseDialog },
    },
    [
      _c("template", { slot: "modalBody" }, [
        _c("h2", { staticClass: "text-bold" }, [
          _vm._v("削除します。よろしいですか？"),
        ]),
        _vm._v(" "),
        _c("h6", [_vm._v(_vm._s(_vm.detailContent))]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modalFooter" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "info" },
              on: {
                click: function ($event) {
                  return _vm.confirm(false)
                },
              },
            },
            [_vm._v("キャンセル")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirm(true)
                },
              },
            },
            [_vm._v("\n      削除\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }