var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-bar" }, [
    _c(
      "nav",
      { staticClass: "navbar app-navbar" },
      [
        _c("a", { staticClass: "navbar-brand d-flex", attrs: { href: "#" } }, [
          _c("img", {
            staticClass: "navbar-logo",
            attrs: { src: require("@/assets/images/icons/veho_icon.png") },
          }),
          _vm._v(" "),
          _c("h4", { staticClass: "veho-title" }, [_vm._v("V-Mail")]),
        ]),
        _vm._v(" "),
        _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-end align-items-center" },
          [
            _c(
              "b-button",
              {
                staticClass: "d-flex align-items-center green-color",
                attrs: { size: "md" },
              },
              [
                _c("b-icon", {
                  attrs: { icon: "person-fill", "aria-hidden": "true" },
                }),
                _vm._v(" " + _vm._s(_vm.email) + "\n      "),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass:
                  "d-flex align-items-center green-color height-logout",
                attrs: { size: "md" },
                on: { click: _vm.handleLogout },
              },
              [
                _c("b-icon", {
                  attrs: { icon: "power", "aria-hidden": "true" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }