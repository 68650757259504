<template>
  <div class="view-page">
    <!-- <el-row :gutter="20">
      <el-col :span="4">
        <Sidebar />
      </el-col>
      <el-col :span="20">
        <router-view :key="key" />
      </el-col>
    </el-row> -->
    <div class="row">
      <div class="col-sm-3 col-md-2 col-lg-2 col-xl-2"><Sidebar /></div>
      <div class="col-sm-9 col-md-10 col-lg-10 col-xl-10"><router-view :key="key" /></div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
export default {
  name: 'AppMain',
  components: {
    Sidebar,
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style scoped>
.view-page {
  margin-top: 66px;
}
</style>
