<template>
  <div class="select-custom">
    <Select2
      :class="{
        'invalid-select': invalidMessage
      }"
      :value.sync="value"
      :options="options"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="(e) =>changeEvent(e)"
    />
    <span v-if="invalidMessage" class="text-red">{{ invalidMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'VSelect2',
  components: {
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    invalidMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  created() {
  },
  methods: {
    changeEvent(newValue) {
      // this.$emit('change', newValue);
      this.$emit('input', newValue);
      this.$emit('update:value', newValue); // To update v-model, maybe we need update emit input
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.invalid-select {
  ::v-deep .select2-selection--single {
    border: 1px solid red !important;
  }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
