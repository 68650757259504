import { getToken } from '@/utils/handleToken';
import { getUserInfo } from '@/utils/getProfile';
import router, { resetRouter } from '@/router';
import { getCurrentRoutes } from '@/utils/handleRole';
import getPageTitle from '@/utils/getPageTitle';
import { MakeToast } from '../utils/toastMessage';
import store from '../store';
import i18n from '@/lang';
import { getMenu } from '@/api/modules/landingPages';

const whiteList = [
  '/login',
  '/page/:url_direct',
  '/m-undelivered',
  '/forget-password',
  '/api/auth/password-reset',
];

async function getMenuPage() {
  const response = await getMenu();
  const listLandingPage = response.data.data.list_url;
  const newRouter = [];
  for (const page of listLandingPage) {
    newRouter.push({
      path: '/' + page,
      name: 'Landing Page',
      component: () => import('@/pages/Page/landing.vue'),
    });
  }
  return newRouter;
}

let toastShowed = false;
getMenuPage().then(newRouter => {
  newRouter.length > 0 && newRouter.forEach(route => {
    router.addRoute(route);
  });
  router.beforeEach(async(to, from, next) => {
    // console.log('router: ', from, to);

    store.dispatch('app/historyRouter', { from, to });
    document.title = getPageTitle(to.meta.title);

    const TOKEN = getToken();
    const USERINFO = getUserInfo();
    const currentRoute = getCurrentRoutes();

    if (TOKEN) {
      if (to.path === '/login') {
        next({ path: '/' });
        store.dispatch('routerParam/setCurrentUrl', { path: '/' });
      } else {
        const userType = USERINFO.type;
        const requiresType = to.meta.role;
        if (requiresType) {
          if (Array.isArray(requiresType)) {
            // Nếu requiresType là một mảng, kiểm tra xem userType có nằm trong mảng đó hay không
            if (requiresType.includes(userType)) {
              next();
              store.dispatch('routerParam/setCurrentUrl', { path: to.path });
            } else {
              if (!toastShowed) {
                MakeToast({
                  variant: 'warning',
                  title: 'warning',
                  content: i18n.t('NOT_PERMISSION'),
                });
                toastShowed = true;
                // Nếu userType không nằm trong mảng requiresType, chuyển hướng đến trang lỗi hoặc trang khác tùy ý
                // next('/not-permission');
              }
              next(currentRoute);
              store.dispatch('routerParam/setCurrentUrl', { path: currentRoute.path });
            }
          } else {
            // Nếu requiresType là một giá trị đơn, kiểm tra xem userType có bằng giá trị đó hay không
            if (userType === requiresType) {
              next();
              store.dispatch('routerParam/setCurrentUrl', { path: to.path });
            } else {
              // Nếu userType không bằng requiresType, chuyển hướng đến trang lỗi hoặc trang khác tùy ý
              // next('/not-permission');
              MakeToast({
                variant: 'warning',
                title: 'warning',
                content: i18n.t('NOT_PERMISSION'),
              });
              next(currentRoute);
              store.dispatch('routerParam/setCurrentUrl', { path: currentRoute.path });
            }
          }
        } else {
          next();
          store.dispatch('routerParam/setCurrentUrl', { path: to.path });
        }
      }
    } else {
      resetRouter();

      if (whiteList.indexOf(to.matched[0] ? to.matched[0].path : '') !== -1) {
        next();
        store.dispatch('routerParam/setCurrentUrl', { path: to.path });
      } else {
        next(`/login`);
        store.dispatch('routerParam/setCurrentUrl', { path: '/login' });
      }
    }
  });
});

