<template>
  <div class="input-container">
    <el-form-item
      :prop="keyValidate"
      :rules="rules"
    >

      <label v-if="label" :for="inputId">{{ label }}</label>
      <el-input
        :id="inputId"
        :class="classInput"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :show-password="isPassword"
        :max-length="maxLength"
        :size="size"
        @input="handleInput"
        @change="handleChange"
      />
    </el-form-item>
  </div>
</template>

<script>

export default {
  name: 'NormalInput',
  components: {
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    classInput: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    keyValidate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      },

    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      inputModel: '',
    };
  },
  computed: {
    currentLang() {
      return this.store.getters.language;
    },
  },
  created() {
  },
  methods: {
    handleInput(e) {
      // Emit the input event for v-model to update the parent data
      this.$emit('input', e);
    },
    handleChange(value) {
      // Emit the input event for v-model to update the parent data
      this.$emit('change', value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
::v-deep .el-form-item__content {
  line-height: 22px;
}
.border-radius-none {
  ::v-deep .el-input__inner {
  height: 33px !important;
  border-radius: unset !important;
  }
  }
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}
</style>
