import Cookies from 'js-cookie';
import constAuth from '@/const/auth';

/**
 * Function get Object Profile in Cookies
 * @returns Return Object Profile
 */
export function getProfile() {
  let PROFILE = Cookies.get('profile');
  if (PROFILE) {
    return JSON.parse(PROFILE);
  }
  PROFILE = constAuth.PROFILE;
  return PROFILE;
}

export function getUserInfo() {
  const USERINFO = Cookies.get('user_info');
  if (USERINFO) {
    return JSON.parse(USERINFO);
  }
  return null;
}
