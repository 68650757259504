var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-page" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-3 col-md-2 col-lg-2 col-xl-2" },
        [_c("Sidebar")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-9 col-md-10 col-lg-10 col-xl-10" },
        [_c("router-view", { key: _vm.key })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }