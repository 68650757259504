var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-notification",
    {
      attrs: {
        "icon-type": _vm.confirmIconHtml || "question",
        "show-modal": _vm.showModal,
      },
      on: { handleCloseDialog: _vm.handleCloseDialog },
    },
    [
      _c("template", { slot: "modalBody" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.confirmIconHtml) } }),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.confirmTitle) } }),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.confirmContent) } }),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modalFooter" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "info" },
              on: {
                click: function ($event) {
                  return _vm.confirm(false)
                },
              },
            },
            [_vm._v("キャンセル")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirm(true)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.confirmBtnTxt) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }